<template>
<div>
  <van-nav-bar
  title="星座运势"
  left-arrow
  :fixed="true"
  @click-left="Back"></van-nav-bar>
<!--商品图片 :autoplay="3000" -->
<div class="goods"  >
<div class="flex-action"> 
<div class="flex-cur" style="flex-direction:column" v-for="(i,key) in flexcontent" :key="key">
<img :src="i.img"  @click="$router.push({name:'Constellationdetail',query: {name:i.name,index:key}})">
<p class="info2">{{i.name}}</p>
<p class="info2">{{i.content}}</p>
</div>
</div>
    
  </div>

  </div>
</template>

<script>

export default {

  data() {
    return {
       flexcontent: [
        {
          name: "白羊座",
          img:
            "img/star/baiyang.png",
          content:'3.21-4.19'
        },
       {
          name: "金牛座",
          img:
            "img/star/jinniu.png",
          content:'4.20-5.20'
        },
        {
          name: "双子座",
          img:
            "img/star/shuangzi.png",
          content:'5.21-6.21'
        },
        {
          name: "巨蟹座",
          img:
            "img/star/juxie.png",
          content:'6.22-7.22'
        },
        {
          name: "狮子座",
          img:
            "img/star/shizi.png",
          content:'7.23-8.22'
        },
        {
          name: "处女座",
          img:
            "img/star/chunv.png",
          content:'8.23-9.22'
        },
       {
          name: "天秤座",
          img:
            "img/star/tianping.png",
          content:'9.23-10.23'
        },
        {
          name: "天蝎座",
          img:
            "img/star/tianxie.png",
          content:'10.24-11.22'
        },
        {
          name: "射手座",
          img:
            "img/star/sheshou.png",
          content:'11.23-12.21'
        },
        {
          name: "摩羯座",
          img:
            "img/star/mojie.png",
          content:'12.22-1.19'
        },
        {
          name: "水瓶座",
          img:
            "img/star/shuiping.png",
          content:'1.20-2.18'
        },
         {
          name: "双鱼座",
          img:
            "img/star/shuangyu.png",
          content:'2.19-3.20'
        },
        ],
    };
  },
   created(){
     
    },
  methods: {
     // 返回原生
    Back()
    {
     let u = navigator.userAgent
     let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //android
     let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
     if(isIOS)
     {
          window.webkit.messageHandlers.close.postMessage('up');   
     }
     if (isAndroid) {
       window.android.close(); 
     } 
    },
  },


};
</script>

<style lang="less" scoped>
.goods {
padding-bottom: 50px;
 margin-top:0px;
  background: url(/img/star/592d404d7acaf.png)
    100% repeat;
  width: 100%;
  background-size: 100%;
  justify-content: flex-end;
  padding-bottom: 0px;
  align-items: center;
  position:fixed;
  height: 100%;
  &-swipe {
    img {
  width: 81px;
  height: 81px;
  border-radius: 10px;
  border: 3px solid rgba(39, 47, 105, 1);
    }
  }

.flex-action {
  display: flex;
  padding-top: 80px;
  flex-wrap: wrap;
}
.flex-cur {

   display: flex;
   align-items: center;
   width: 33%;
   flex-direction: column;
   }
.flex-cur img {
  width: 81px;
  width: 81px;
 
}
}
.info2 {
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  letter-spacing: -0.6666666865348816px;
  font-family: PingFangSC-Regular;
  line-height: 22px;
  text-align: center;
  align-self: center;
}
</style>
